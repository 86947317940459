// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bS_kw";
export var caseStudyBackground__lineColor = "bS_kt";
export var caseStudyHead__imageWrapper = "bS_kp";
export var caseStudyProjectsSection = "bS_kx";
export var caseStudyQuote__bgRing = "bS_kr";
export var caseStudySolution__ring = "bS_kB";
export var caseStudySolution__ringThree = "bS_lJ";
export var caseStudySolution__ringTwo = "bS_kC";
export var caseStudyVideo__ring = "bS_kG";
export var caseStudy__bgDark = "bS_km";
export var caseStudy__bgLight = "bS_kl";
export var caseStudy__bgLightGray = "bS_mR";