import { Link } from 'gatsby'
import { getImage, StaticImage } from 'gatsby-plugin-image'

import solutionBgRingOne from '~/assets/images/case-study/pmk/bg-solution-rings-1.svg?file'
import solutionBgRingTwo from '~/assets/images/case-study/pmk/bg-solution-rings-2.svg?file'
import solutionBgRingThree from '~/assets/images/case-study/pmk/bg-solution-rings-3.svg'
import videoReviewLogo from '~/assets/images/case-study/pmk/video-review-logo.svg?file'
import videoRingsImage from '~/assets/images/case-study/pmk/video-ring.svg'
import MainLayout from '~/components/layouts/MainLayout'
import ContactFormSection from '~/components/shared/ContactFormSection'
import LazyHydrate from '~/components/shared/LazyHydrate'
import { CommonImageProps } from '~/types/common-props'
import { fileToImageLikeData } from '~/utils'
import CaseStudyBackground from '~/views/CaseStudies/components/CaseStudyBackground'
import CaseStudyClientVideoReview from '~/views/CaseStudies/components/CaseStudyClientVideoReview'
import CaseStudyCta from '~/views/CaseStudies/components/CaseStudyCta'
import CaseStudyHead from '~/views/CaseStudies/components/CaseStudyHead'
import CaseStudyPreview from '~/views/CaseStudies/components/CaseStudyPreview'
import CaseStudyPreviewHead from '~/views/CaseStudies/components/CaseStudyPreviewHead'
import CaseStudyQuote from '~/views/CaseStudies/components/CaseStudyQuote'
import CaseStudySolution from '~/views/CaseStudies/components/CaseStudySolution'
import CaseStudySolutionHead from '~/views/CaseStudies/components/CaseStudySolutionHead'
import CaseStudyTech from '~/views/CaseStudies/components/CaseStudyTech'
import CaseStudyVideoCase from '~/views/CaseStudies/components/CaseStudyVideo'

import * as containerStyles from './PlanMyKids.module.scss'
import usePlanMyKidsStaticQuery from './usePlanMyKidsStaticQuery'

const imageProps: CommonImageProps = {
  width: 80,
  height: 80,
}

const quotes = {
  client: {
    name: 'David Watkins',
    position: 'Founder of PlanMyKids',
  },
  pm: {
    name: 'Eugenia',
    position: 'Project Manager at Codica',
  },
}

const previewBabelTitle = 'Insurance Progressive Web App'
const previewXPCTitle = 'Accommodation Search Website'

const CaseStudiesPlanMyKids = () => {
  const query = usePlanMyKidsStaticQuery()

  const bannerCase = getImage(fileToImageLikeData(query.bannerCase))
  const clientVideoReview = getImage(
    fileToImageLikeData(query.clientVideoReview),
  )
  const solutionDeliveredOne = getImage(
    fileToImageLikeData(query.solutionDeliveredOne),
  )
  const solutionDeliveredTwo = getImage(
    fileToImageLikeData(query.solutionDeliveredTwo),
  )
  const solutionDeliveredThree = getImage(
    fileToImageLikeData(query.solutionDeliveredThree),
  )
  const solutionDeliveredFour = getImage(
    fileToImageLikeData(query.solutionDeliveredFour),
  )
  const solutionDeliveredFive = getImage(
    fileToImageLikeData(query.solutionDeliveredFive),
  )
  const solutionDeliveredSix = getImage(
    fileToImageLikeData(query.solutionDeliveredSix),
  )
  const videoCaseImage = getImage(fileToImageLikeData(query.videoCaseImage))

  return (
    <MainLayout isTransparent scrollTop>
      <CaseStudyHead
        title="Online Service Marketplace for PlanMyKids"
        subtitle="Learn how Codica helped a customer build an intuitive online marketplace for parents for booking after-school activities for kids."
        image={bannerCase}
        classNameImageWrapper={containerStyles.caseStudyHead__imageWrapper}
        imageAlt="Home page of the activity booking marketplace PlanMyKids"
        imageTitle="Home page of the online booking platform PlanMyKids"
        domain="Leisure"
        location="Raleigh, North Carolina, USA"
        timeline="November 2020 - March 2021"
        services={
          <>
            <Link to="/services/product-discovery/" key="productKey">
              Product discovery
            </Link>
            , Prototyping,
            <Link key="designKey" to="/services/ui-ux-design/" className="ml5">
              UX/UI design services
            </Link>
            , Front-end and Back-end development,
            <Link
              to="/services/progressive-web-apps-development/"
              className="ml5"
              key="pwaDevKey"
            >
              PWA development
            </Link>
            ,
            <Link
              to="/services/mvp-development/"
              className="ml5"
              key="mvpDevKey"
            >
              MVP development
            </Link>
          </>
        }
        team="1 Project Manager/Business Analyst, 1 UX/UI Designer, 2 Backend Developers, 1 Frontend Developer, 1 Devops, 1 QA Engineer."
        technologies="Ruby on Rails, React, Redis, AWS"
      />

      <div className={containerStyles.caseStudy__bgLightGray}>
        <LazyHydrate whenVisible>
          <CaseStudyClientVideoReview
            color="videoReviewPmk"
            link="https://www.youtube.com/embed/dzxMCDLghZQ"
            clientImage={clientVideoReview}
            imageTitle="Customer review about online service marketplace project"
            logo={videoReviewLogo}
            name="David Watkins, Founder of PlanMyKids"
            review={[
              {
                item: '“The PlanMyKids journey started when my family struggled to plan our own kid’s activities while not in school. As busy parents, we were time-constrained, the information we needed was all over the place, and coordinating with family and friends felt like it required a project management certification.',
              },
              {
                item: 'After talking with other parents it turned out that many felt the same way. We felt we could change the status quo by creating a care-free, personalized, trusted digital service to connect parents and program providers.”',
              },
            ]}
            bgRings
            bgRingImage={videoRingsImage}
          />
        </LazyHydrate>
      </div>

      <CaseStudyBackground
        backgroundColor={containerStyles.caseStudy__bgDark}
        lineColor={containerStyles.caseStudyBackground__lineColor}
        bgTextLeft="The project is a simple personalized online booking platform for booking leisure activities for children. With its help, parents are able to find initiatives that match their children’s interests and easily coordinate their activities."
        bgTextRight="The main goal of the product is to connect parents with the providers of kids activities nearby and free them from stress that comes with planning, purchasing, and attending such activities."
        sectionBusiness={[
          {
            label:
              'Build an MVP to help the customer reduce risks associated with custom marketplace development. The MVP approach allows meeting the basic kids activities marketplace requirements without investing too much money.',
          },
          {
            label:
              'Create a personalized service marketplace to connect parents with providers of kids’ activities.',
          },
          {
            label:
              'Create a user-friendly online marketplace for parents that would run smoothly on both desktop and mobile devices.',
          },
          {
            label:
              'Find the most suitable way to collect users’ data to provide them with a great variety of activities for children that fall under specific criteria.',
          },
        ]}
        sectionValueDelivered={[
          {
            label:
              'Delivered a service marketplace MVP under budget which was crucial for a bootstrapping company. It helped customer test value proposition and marketing message.',
          },
          {
            label:
              'Helped the customer narrow down the MVP features for activity booking marketplace which were brought to life through prototypes. This way, the customer stayed focused on the main MVP goals without being distracted on fancy functionality.',
          },
          {
            label:
              'Created a children’s leisure online planner. The platform allows parents to find after-school activities based on kids’ interests, family preferences, and budget. The platform has convenient account management and administrative dashboards. They allow parents to plan the activities for children and coordinate them with family and friends.',
          },
          {
            label:
              'Built a cross-platform progressive web application that provides users with enhanced mobile experience. The PWA technology also helped the customer save costs on mobile app development.',
          },
        ]}
      />

      <CaseStudyQuote
        quote={[
          {
            text: '“I really enjoyed working on the project! Thanks to great David’s ideas, our close cooperation and his trust in our expertise, we were able to quickly deliver a user-friendly and useful online service marketplace.',
          },
          {
            text: 'As usual, our main goal was to create a logical product workflow based on specific business objectives and coordinate it with the customer. The best thing about this project is that effective communication and mutual trust allowed us to turn the initial idea into a working solution within just a few months.”',
          },
        ]}
        photo={
          <StaticImage
            src="../../../../assets/images/avatars/PM-Eugenia.png"
            alt={`${quotes.pm.name}, ${quotes.pm.position} at Codica`}
            title={`${quotes.pm.name}, ${quotes.pm.position}`}
            {...imageProps}
          />
        }
        bgRing={containerStyles.caseStudyQuote__bgRing}
        name={quotes.pm.name}
        position={quotes.pm.position}
      />

      <CaseStudyCta
        backgroundColor={containerStyles.caseStudy__bgDark}
        title="Do you need a reliable team to build your custom web product?"
        description="We apply best practices to help you save costs and launch quickly."
        nameButton="Talk to an expert"
        linkButton="/contacts/"
      />

      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudySolutionHead title="Solution delivered" />
        <CaseStudySolution
          image={solutionDeliveredOne}
          title="Smart online booking platform"
          description="The kids activities marketplace is noted for its smooth and straightforward workflow. Parents fill in the questionnaire and send it to review. The system automatically finds relevant activities based on their children’s interests. Program specialists review automatic suggestions, form an itinerary and send it to parents."
          alt="User-friendly activity booking marketplace PlanMyKids"
          imageTitle="User-friendly online booking platform PlanMyKids"
        />
        <CaseStudySolution
          isImageSmall
          isReverse
          image={solutionDeliveredTwo}
          title="Progressive web application"
          description={
            <>
              Since a huge part of users engage with the website on a mobile
              app, we aimed to provide them with a rich user experience without
              them having to download a
              <Link
                to="/services/online-marketplace-development/marketplace-app-development/"
                style={{ color: '#e4282f', textDecoration: 'underline' }}
                className="ml5"
                key="solutionMobileAppKey"
              >
                mobile app marketplace
              </Link>
              . The PWA technology is a perfect way to achieve this. Therefore
              we built a fast-loading progressive web application for this
              activity booking marketplace. The app is cross platform and
              provides a seamless experience for both Android and iPhone users.
            </>
          }
          alt="Progressive web application for the activity booking marketplace PlanMyKids"
          imageTitle="Progressive web application for the online booking platform PlanMyKids"
          bgRings
          bgRingImage={solutionBgRingOne}
          bgRingClass={containerStyles.caseStudySolution__ring}
        />
        <CaseStudySolution
          image={solutionDeliveredThree}
          title="UX and UI design"
          description="Since our task was to build a child-focused activity booking marketplace, we intended to make it less formal. For this purpose, we added little icons to the web pages that remind children’ sloppy drawings. Also, we built a funny and cute 404 error page. Finally, we paid special attention to typography. We chose two types of fonts. Serif was used for headlines and Sans Serif - for longer copy to improve readability."
          alt="Visually appealing design of the booking marketplace PlanMyKids"
          imageTitle="Visually appealing design of the online booking platform PlanMyKids"
          bgRings
          bgRingImage={solutionBgRingTwo}
          bgRingClass={containerStyles.caseStudySolution__ringTwo}
        />
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredFour}
          title="Easy web forms with Zoho integration"
          description="The integration with Zoho Forms allowed building mobile-friendly online forms. They were used to make the questionnaire and sign-up forms very easy to fill in even for smartphone users. With its help, parents can submit the initial questionnaire quickly and effortlessly."
          alt="Integration with Zoho for creating mobile friendly mobile forms"
          imageTitle="Integration with Zoho for building mobile-friendly online forms"
        />
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredFive}
          title="Convenient itinerary management"
          description="We focused on making itinerary management easy for both parents and program specialists. Thus, specialists can search for camps available and filter the results based on dates, age, price, and activities. Also, they can view short descriptions of courses to offer parents the most relevant options. As soon as the relevant camps are added, and the itinerary is formed, it can be sent to parents."
          alt="Convenient itinerary management on the booking marketplace"
          imageTitle="Convenient itinerary management on the online booking platform PlanMyKids"
          bgRings
          bgRingImage={solutionBgRingThree}
          bgRingClass={containerStyles.caseStudySolution__ringThree}
        />
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredSix}
          title="Calendar for parents"
          description="With this online booking platform functionality, parents can view all planned itineraries. Also, they can choose child/children whose itinerary they want to view. This way, parents will always have their kids’ schedule at their fingertips and never miss an important event."
          alt="Convenient calendar on the booking marketplace PlanMyKids"
          imageTitle="Convenient calendar on the online booking platform PlanMyKids"
        />
      </div>

      <LazyHydrate whenVisible>
        <CaseStudyVideoCase
          title="How it works"
          description="The video below shows a detailed platform user flow for parents. It walks you through the key stages of the activity booking process from filling in the questionnaire to receiving a personalized itinerary."
          ringsClass={containerStyles.caseStudyVideo__ring}
          image={videoCaseImage}
          videoLink="https://www.youtube.com/embed/_68Np1a5P-M"
        />
      </LazyHydrate>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudyTech
          technologies={[
            { label: 'React', icon: 'react' },
            { label: 'Rails API', icon: 'ror' },
            { label: 'AWS', icon: 'aws' },
            { label: 'Redux', icon: 'redux' },
            { label: 'Sidekiq', icon: 'sidekiq' },
            { label: 'Redis', icon: 'redis' },
            { label: 'Gitlab CI', icon: 'gitlab' },
          ]}
          integrations={[
            { label: 'Zoho', icon: 'zoho' },
            { label: 'Stripe', icon: 'stripe' },
            { label: 'Google APIs', icon: 'googleApi' },
            { label: 'FB login', icon: 'facebookLogin' },
          ]}
        />
      </div>

      <CaseStudyQuote
        isBottom
        quote={[
          {
            text: '“Working with the Codica team has been a great experience. All the value we thought they would provide during the selection process was met during the delivery.',
          },
          {
            text: 'Our team spent a good time working with a parent focus group to narrow down our MVP features which Codica brought to life through prototypes. When we got too ambitious with features, Codica team helped ground us with reminders of our MVP goals.',
          },
          {
            text: 'In the end Codica delivered our online service marketplace MVP under budget, which for a bootstrapped company was important.”',
          },
        ]}
        photo={
          <StaticImage
            src="../../../../assets/images/avatars/clients/client-pmk.png"
            alt={`${quotes.client.name}, ${quotes.client.position} at Codica`}
            title={`${quotes.client.name}, ${quotes.client.position}`}
            {...imageProps}
          />
        }
        bgRing={containerStyles.caseStudyQuote__bgRing}
        name={quotes.client.name}
        position={quotes.client.position}
      />

      <div className={containerStyles.caseContactFormSection}>
        <ContactFormSection title="Let’s build a great custom product together!" />
      </div>

      <div className={containerStyles.caseStudyProjectsSection}>
        <div className="container case-wrapper-xl">
          <div className="row">
            <CaseStudyPreviewHead title="Other projects" />
            <CaseStudyPreview
              title={previewBabelTitle}
              color="babelColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/babel.png"
                  alt={`Case study: ${previewBabelTitle} | Codica`}
                  title={`Case study: ${previewBabelTitle}`}
                  placeholder="blurred"
                  width={350}
                  objectFit="fill"
                />
              }
              url="insurance-progressive-web-application"
            />
            <CaseStudyPreview
              title={previewXPCTitle}
              color="xpcColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/xpc.png"
                  alt={`Case study: ${previewXPCTitle} | Codica`}
                  title={`Case study: ${previewXPCTitle}`}
                  placeholder="blurred"
                  objectFit="contain"
                />
              }
              url="accommodation-search-website-mvp"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CaseStudiesPlanMyKids
